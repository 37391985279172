.delivery-try.ant-steps-item
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #ffb74d !important;
}

.delivery-try.ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #ffb74d !important;
}

.route-end.ant-steps-item
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: #ff1212 !important;
}

.route-end.ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #ff1212 !important;
}

.route-start.ant-steps-item
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: blue !important;
}

.route-start.ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: blue !important;
}

.delivered.ant-steps-item
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background-color: rgb(82, 196, 26) !important;
}
.delivered.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail:after {
  background-color: rgb(82, 196, 26) !important;
}

.delivery-try.ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #c9984e !important;
}

.route-map.leaflet-container {
  height: 60vh;
  width: 100%;
  z-index: 9 !important;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.forced-spin.ant-spin-nested-loading {
  display: flex;
  flex: 1;
}
.forced-spin.ant-spin-container {
  display: flex;
  flex: 1;
}
